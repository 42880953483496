<template>
  <div>
    <c-table
      ref="grid"
      title="Spare Part 목록"
      :columns="grid.columns"
      :data="grid.data"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="materialCd"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="추가" icon="add" @btnClicked="addRow" v-if="editable" />
          <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-material',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  computed: {
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '품명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'materialStandard',
            field: 'materialStandard',
            label: '규격',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'materialQuality',
            field: 'materialQuality',
            label: '재질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '제조사',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
        ],
        data: [],
        height: '',
      },
      searchParam: {
        equipmentCd: '',
      },
      listUrl: '',
      insertUrl: '',
      deleteUrl: '',
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.material.list.url;
      this.insertUrl = transactionConfig.mdm.equipment.material.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.material.delete.url;
      
      this.grid.height = (window.innerHeight - 260) + 'px';

      this.searchParam.equipmentCd = this.param.equipmentCd;
      this.getList();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '정비자재 마스터 상세';
      this.popupOptions.param = {
        plantCd: this.param.plantCd,
        materialCd: row ? row.materialCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/sp/spareDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    addRow() {
      this.popupOptions.title = '작업자재 추가'; // 작업자재 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRow;
    },
    closeaddRow(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { materialCd: item.materialCd }) === -1) {
            saveData.push({
              regUserId: this.$store.getters.user.userId,
              equipmentCd: this.param.equipmentCd,
              materialCd: item.materialCd,
            });
          }
        });

        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
